import cx from 'classnames';

import { getEquipmentByKind } from '@sb/integrations/loaders/getEquipmentByKind';
import type { Step } from '@sb/remote-control/types';
import { STEP_NAME_BY_STEP_KIND } from '@sb/remote-control/util/constants';
import type { StepKind } from '@sb/routine-runner';
import { useFeatureFlag } from '@sbrc/hooks';
import { INTEGRATION_STEP_LIST } from '@sbrc/utils';

import { useRoutineEditorContext } from '../routine-editor/shared';

import { STEP_KIND_TO_LIBRARY_DESCRIPTION, STEP_LIBRARY } from './shared';

interface StepLibraryProps {
  onSelect: (step: StepKind) => void;
  isDisabled?: boolean;
}

export function StepLibrary({ onSelect, isDisabled }: StepLibraryProps) {
  const { routine } = useRoutineEditorContext();

  const isRunInBackgroundStepEnabled = useFeatureFlag('runInBackgroundStep');
  const classifyEnabled = useFeatureFlag('classifyStep');
  const locateEnabled = useFeatureFlag('locateStep');
  const isAlohaControlEnabled = useFeatureFlag('alohaControl');
  const pushModeEnabled = useFeatureFlag('forceFeedback');

  const filterStepKinds = (stepKind: Step.Kind) => {
    // if integration step, ensure the integration is added
    if (INTEGRATION_STEP_LIST.includes(stepKind)) {
      return routine.equipment.some((eq) =>
        getEquipmentByKind(eq.kind).getSupportedSteps().includes(stepKind),
      );
    }

    if (stepKind === 'RunInBackground') {
      return isRunInBackgroundStepEnabled;
    }

    if (stepKind === 'RunSkill') {
      return isAlohaControlEnabled;
    }

    if (stepKind === 'Classify') {
      return classifyEnabled;
    }

    if (stepKind === 'Locate') {
      return locateEnabled;
    }

    if (stepKind === 'PushMode') {
      return pushModeEnabled;
    }

    return true;
  };

  return (
    <section className={cx('tw-flex', 'tw-flex-col', 'tw-gap-16')}>
      {STEP_LIBRARY.map(({ stepKinds, title }) => {
        const filteredStepKinds = stepKinds.filter(filterStepKinds);

        if (filteredStepKinds.length === 0) {
          return false;
        }

        return (
          <section
            key={title}
            className={cx('tw-flex-1', 'tw-flex', 'tw-flex-col')}
          >
            <h5 className="tw-heading-40">{title}</h5>

            <div className={cx('tw-grid', 'tw-gap-12', 'tw-grid-cols-2')}>
              {filteredStepKinds.map((stepKind) => (
                <div
                  key={STEP_NAME_BY_STEP_KIND[stepKind]}
                  className={cx(
                    'tw-surface-elevated',
                    'tw-shadow-30',
                    'tw-px-16',
                    'tw-py-12',
                    'tw-rounded-10',
                    isDisabled && 'tw-opacity-60',
                    isDisabled && 'tw-cursor-not-allowed',
                  )}
                  onClick={isDisabled ? undefined : () => onSelect(stepKind)}
                  role="button"
                >
                  <h6 className={cx('tw-text-17', 'tw-mb-2')}>
                    {STEP_NAME_BY_STEP_KIND[stepKind]}
                  </h6>
                  <p className={cx('tw-text-13', 'tw-text-label-tertiary')}>
                    {STEP_KIND_TO_LIBRARY_DESCRIPTION[stepKind]}
                  </p>
                </div>
              ))}
            </div>
          </section>
        );
      })}
    </section>
  );
}
