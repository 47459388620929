import { useEffect, useState } from 'react';

import { Badge } from '@sb/design-system';
import type { Robot } from '@sb/remote-control/types';
import { onGetRobots } from '@sbrc/services';

interface RobotLoadStatusProps {
  routineID: string;
}

export default function RobotLoadStatus({ routineID }: RobotLoadStatusProps) {
  const [robots, setRobots] = useState<Robot.ConvertedResponse[]>();

  useEffect(() => {
    const unsubscribe = onGetRobots({ routineID }, setRobots);

    return () => {
      unsubscribe();
    };
  }, [routineID]);

  const isLoaded = robots && robots.length > 0;

  if (!isLoaded) return null;

  return <Badge color="Blue">Loaded</Badge>;
}
