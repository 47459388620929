import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

import {
  Button,
  Modal,
  SimpleModalContent,
  TextInput,
} from '@sb/design-system';
import type { Routine } from '@sb/remote-control/types';
import FullPageLoader from '@sbrc/components/FullPageLoader';
import PrivatePage from '@sbrc/components/user-sessions/PrivatePage';
import {
  usePageRobotAndRoutine,
  useAuthentication,
  useToast,
} from '@sbrc/hooks';
import { createRoutine } from '@sbrc/services';
import {
  getLinkToRoutinePage,
  getLinkToRoutineCreateGuidedPage,
} from '@sbrc/utils';

import { setRoutineLockedState } from '../../routine-editor/routineLockedState';

import { getBlankRoutineTemplate } from './getBlankRoutineTemplate';

type Section = 'blank' | 'guided' | 'template';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  selectedSection: Section;
  selectedId: string | null;
  defaultName: string;
  description: string;
  routineTemplates?: Array<Routine.ConvertedResponse>;
};

export const NewRoutineModal = (props: Props) => {
  const {
    selectedSection,
    selectedId,
    defaultName,
    description,
    routineTemplates,
    isOpen,
    onClose,
  } = props;

  const { robot } = usePageRobotAndRoutine();
  const { settings } = useAuthentication();
  const { push } = useRouter();
  const { setToast } = useToast();

  const [disabled, setDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const [name, setName] = useState('');

  const selectedTemplate = routineTemplates?.find(
    (template) => selectedSection === 'template' && template.id === selectedId,
  );

  useEffect(() => {
    setName(defaultName);
  }, [defaultName]);

  const submit = async () => {
    setDisabled(true);

    try {
      if (selectedSection === 'guided') {
        setDisabled(false);
        onClose();
        await push(getLinkToRoutineCreateGuidedPage('machine-tending'));

        return;
      }

      const template =
        selectedSection === 'template'
          ? selectedTemplate
          : getBlankRoutineTemplate();

      const newRoutine: Partial<Routine.EditableFields> = {
        ...template,
        currentEditorID: null,
        description,
        isTemplate: false,
        name,
      };

      const newRoutineID = await createRoutine(newRoutine, settings!);
      setRoutineLockedState(newRoutineID, false);

      onClose();
      setDisabled(false);
      await push(getLinkToRoutinePage(newRoutineID));
    } catch (e) {
      setDisabled(false);
      setErrorMessage(e.message);
      console.error('Failed to create routine', e); // eslint-disable-line no-console

      setToast({
        kind: 'error',
        message: 'Failed to create routine',
      });
    }
  };

  if (!robot) {
    return <FullPageLoader label="Loading robot" />;
  }

  if (!isOpen) return null;

  return (
    <PrivatePage>
      <Modal isOpen={isOpen} onClose={onClose} data-testid="new-routine-widget">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            submit();
          }}
        >
          <SimpleModalContent
            title="Name routine"
            buttons={
              <>
                <Button
                  className="tw-rounded-6"
                  data-testid="new-routine-cancel-button"
                  disabled={disabled}
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="Filled"
                  className="tw-rounded-6"
                  data-testid="new-routine-create-button"
                  disabled={disabled}
                >
                  OK
                </Button>
              </>
            }
          >
            <p>Enter a name for this routine.</p>
            <TextInput
              className="tw-mt-16"
              placeholder="Routine name"
              disabled={disabled}
              value={name}
              onChange={(value) => {
                setName(value);
              }}
            />
            {errorMessage && (
              <p className="tw-pt-8 tw-pl-16 tw-text-13 tw-text-red tw-text-left">
                {errorMessage}
              </p>
            )}
          </SimpleModalContent>
        </form>
      </Modal>
    </PrivatePage>
  );
};
