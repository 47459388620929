import type { Dispatch, SetStateAction } from 'react';
import { createContext, useContext } from 'react';

import type { Robot, Routine, Step } from '@sb/remote-control/types';

export type RoutineLockedState = 'locked' | 'unlocked';

/** State for the entire routine editor page. */
export interface RoutineEditorContextState {
  changedSteps: Step.ConvertedSummary[] | null;
  copyStepIDs: string[];
  lockState: RoutineLockedState;
  isRoutineLoaded: boolean;
  isRoutineRunning: boolean;
  isRoutineLocked: boolean;
  lastStepPlayed: Step.ConvertedConfiguration | null;
  routine: Routine.ConvertedResponse;
  selectedRobot: Robot.ConvertedResponse;
  selectedStepID: string | null;
  setLockState: Dispatch<SetStateAction<RoutineLockedState>>;
  setChangedSteps: Dispatch<SetStateAction<Step.ConvertedSummary[] | null>>;
  setCopyStepIDs: Dispatch<SetStateAction<string[]>>;
  setLastStepPlayed: Dispatch<
    SetStateAction<Step.ConvertedConfiguration | null>
  >;
  setSelectedStepID: Dispatch<SetStateAction<string | null>>;
}

export const RoutineEditorContext = createContext<RoutineEditorContextState>({
  changedSteps: null,
  copyStepIDs: [],
  isRoutineLoaded: false,
  isRoutineRunning: false,
  isRoutineLocked: true,
  lockState: 'locked',
  lastStepPlayed: null,
  routine: {} as any,
  selectedRobot: {} as any,
  selectedStepID: null,
  setChangedSteps: () => {},
  setCopyStepIDs: () => {},
  setLastStepPlayed: () => {},
  setSelectedStepID: () => {},
  setLockState: () => {},
});

export const useRoutineEditorContext = () => {
  return useContext(RoutineEditorContext);
};
