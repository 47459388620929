import cx from 'classnames';

import { Modal, NavigationBar, NavigationBarButton } from '@sb/design-system';
import type { Step } from '@sb/remote-control/types';

import { StepLibrary } from './StepLibrary';

interface StepLibraryModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (stepKind: Step.Kind) => void;
}

export function StepLibraryModal({
  isOpen,
  onClose,
  onSelect,
}: StepLibraryModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <NavigationBar
        contentLeft={
          <NavigationBarButton onClick={onClose}>Cancel</NavigationBarButton>
        }
      >
        Add step
      </NavigationBar>
      <div
        className={cx('tw-w-512', 'tw-overflow-auto', 'tw-px-24', 'tw-pb-24')}
      >
        <StepLibrary onSelect={onSelect} />
      </div>
    </Modal>
  );
}
