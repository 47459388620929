import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import {
  Button,
  Icon,
  Menu,
  MenuItem,
  MenuSeparator,
  Popper,
} from '@sb/design-system';
import type { Routine } from '@sb/remote-control/types';
import { useRoutineList } from '@sbrc/hooks';
import { onGetRoutineTemplates } from '@sbrc/services';

import { NewRoutineModal } from './NewRoutineModal';

type Section = 'blank' | 'guided' | 'template';

export function NewRoutineMenuItems() {
  const routines = useRoutineList();
  const { push } = useRouter();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNewOpen, setIsNewOpen] = useState<boolean>(false);

  const [selectedSection, setSelectedSection] = useState<Section>('blank');
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [defaultName, setDefaultName] = useState('');
  const [defaultDescription, setDefaultDescription] = useState('');

  const [routineTemplates, setRoutineTemplates] =
    useState<Routine.ConvertedResponse[]>();

  useEffect(() => {
    const unsubscribe = onGetRoutineTemplates((templates) => {
      setRoutineTemplates(templates);
    });

    return () => unsubscribe();
  }, []);

  const select = (
    section: Section,
    id: string | null,
    newDefaultName: string,
    newDefaultDescription: string,
  ) => {
    setSelectedSection(section);
    setSelectedId(id);
    setDefaultName(newDefaultName);
    setDefaultDescription(newDefaultDescription);
  };

  const templates = routineTemplates ?? [];

  return (
    <div>
      <Popper
        isOpen={isNewOpen}
        onClose={() => setIsNewOpen(false)}
        offset={8}
        placement="top"
        isDark
        content={
          <Menu className="tw-max-w-256 tw-surface-secondary">
            <MenuItem
              data-testid="add-new-routine-button"
              iconKind="plus"
              onClick={() => {
                setIsModalOpen(true);
                select('blank', null, '', '');
              }}
            >
              New routine
            </MenuItem>

            {templates.length > 0 && <MenuSeparator />}
            {templates.length > 0 &&
              templates.map((row) => (
                <MenuItem
                  key={row.id}
                  iconKind="arrowUturnDown"
                  data-testid="add-new-routine-button"
                  onClick={() => {
                    setIsModalOpen(true);

                    select(
                      'template',
                      row.id,
                      `${row.name} (${new Date().toLocaleDateString()})`,
                      `From template “${row.name}”`,
                    );
                  }}
                >
                  New from &quot;{row.name}&quot;
                </MenuItem>
              ))}
          </Menu>
        }
      >
        <Button
          variant="Elevated"
          className="tw-w-full"
          onClick={() => {
            if (!routineTemplates?.length) {
              setIsModalOpen(true);
              select('blank', null, '', '');
            } else {
              setIsNewOpen(!isNewOpen);
            }
          }}
        >
          <Icon kind="plus" />
          <span>New</span>
        </Button>
      </Popper>

      <NewRoutineModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);

          if (routines?.length === 0) {
            push('/');
          }
        }}
        selectedSection={selectedSection}
        selectedId={selectedId}
        defaultName={defaultName}
        description={defaultDescription}
        routineTemplates={routineTemplates}
      />
    </div>
  );
}
