import { v4 as uuid } from 'uuid';

import type { Step } from '@sb/remote-control/types';
import { STEP_NAME_BY_STEP_KIND } from '@sb/remote-control/util/constants';

export const STEP_KIND_TO_LIBRARY_DESCRIPTION: Record<Step.Kind, string> = {
  AnticipatePayload: 'Sets the expected payload at the end of the arm',
  AddOffset: 'Offset all movements within step',
  Classify: 'Classify a state via vision',
  Locate: 'Locate via vision',
  If: 'Run substeps if condition is met',
  Loop: 'Perform steps repeatedly',
  LoopControl: 'Jump to beginning of a Loop',
  MoveArmTo:
    'Specify a location that the robot should go to by defining the joint or gripper positions',
  NetworkRequest:
    'Send network requests to a device and set environment variable values',
  PressButton:
    'Allows the robot to press a button on a machine, e.g. to start the next CNC cycle',
  PushMode:
    'Enable push mode maintain a pushing force against while performing substeps',
  RunInBackground:
    'Run substeps in the background while also continuing with the steps immediately after this block',
  RunSkill: 'Run an AI learned skill',
  SetEnvironmentVariable: 'Sets the value of an environment variable',
  SetIO:
    "Sets the robot's I/O interface, allowing it to send messages to external devices",
  WaitForConfirmation:
    'Routines will pause and a confirmation pop up will prompt the user to resume the routine',
  Wait: 'Wait for a condition such as time, IO input, or an event',
  ActuateGripper: 'Open or close the gripper to pick up objects',
  ActuateScrewdriver: 'Control attached screwdriver',
  ActuateVacuum: 'Control attached vacuum',
  HaasControlRegion:
    'Specify a section of the routine as controlling a Haas machine accessible over Ethernet, ' +
    'being interrupted by errors from the device and sending a Cell Safe command',
  HaasRunProgram: 'Run a program on a Haas device connected over Ethernet',
};

export const STEP_LIBRARY: { title: string; stepKinds: Step.Kind[] }[] = [
  {
    title: 'Basic',
    stepKinds: [
      'MoveArmTo',
      'ActuateGripper',
      'ActuateScrewdriver',
      'ActuateVacuum',
      'AnticipatePayload',
      'NetworkRequest',
    ],
  },
  {
    title: 'Control',
    stepKinds: [
      'Loop',
      'Wait',
      'If',
      'LoopControl',
      'SetEnvironmentVariable',
      'AddOffset',
      'PushMode',
      'RunInBackground',
      'RunSkill',
    ],
  },
  {
    title: 'Interface with machines',
    stepKinds: ['SetIO', 'PressButton', 'HaasRunProgram', 'HaasControlRegion'],
  },
  {
    title: 'Interface with operators',
    stepKinds: ['WaitForConfirmation'],
  },
  {
    title: 'Vision',
    stepKinds: ['Classify', 'Locate'],
  },
];

export function generateStepSummary({
  stepKind,
  description,
}: {
  stepKind: Step.Kind;
  description?: string;
}): Step.ConvertedSummary {
  return {
    stepKind,
    description,
    name: STEP_NAME_BY_STEP_KIND[stepKind],
    id: uuid(),
    parentSteps: [],
    steps: [],
    stepPosition: 1,
  };
}
