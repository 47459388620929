import type { Routine } from '@sb/remote-control/types';
import { generateStepSummary } from '@sbrc/components/step-library';

/**
 * 'Blank' routine is initialized with a main Loop step
 */
export function getBlankRoutineTemplate(): Partial<Routine.ConvertedResponse> {
  const mainLoop = generateStepSummary({
    stepKind: 'Loop',
    description: 'Main Loop',
  });

  return {
    steps: [mainLoop],
    stepConfigurations: {
      [mainLoop.id]: {
        id: '',
        routineID: '',
        name: '',
        stepKind: 'Loop',
        args: {
          argumentKind: 'Loop',
          condition: null,
          times: null,
        },
      },
    },
  };
}
