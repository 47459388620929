import { useEffect, useState } from 'react';

import type { RoutineLockedState } from './shared';

const STORAGE_KEY = 'routine-editor-locked';

export function setRoutineLockedState(routineID: string, isLocked: boolean) {
  const data = { routineID, isLocked };
  sessionStorage.setItem(STORAGE_KEY, JSON.stringify(data));
}

export function getRoutineLockedState(routineID: string): RoutineLockedState {
  try {
    const jsonData = sessionStorage.getItem(STORAGE_KEY);
    const data = jsonData && JSON.parse(jsonData);

    if (data && data.routineID === routineID && !data.isLocked) {
      return 'unlocked';
    }
  } catch {
    // ignore parse errors
  }

  return 'locked';
}

export function useRoutineLockedState(routineID: string) {
  const [lockState, setLockState] = useState<RoutineLockedState>(() =>
    getRoutineLockedState(routineID),
  );

  useEffect(() => {
    setRoutineLockedState(routineID, lockState === 'locked');
  }, [lockState, routineID]);

  return [lockState, setLockState] as const;
}
